import React from 'react';
import Heading from '../Heading';
import { courseList } from 'data/spring-course-2025';
import { Link } from 'react-router-dom';
import { Title } from '../Title';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__course`;
  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section position-relative`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-10 col-md-11">
            <div className="vstack gap-5 position-relative">
              <div
                className={`${LAYOUT_CLASS}__deco ${LAYOUT_CLASS}__deco--top`}
              />
              <Title
                LAYOUT_CLASS={LAYOUT_CLASS}
                text={'多樣性課程，滿足學習慾望'}
                deco={'COURSE'}
                decoStyle={1}
              />
              <div className="row justify-content-center g-5">
                {courseList.map((o, i) => {
                  const style = i % 2 === 0 ? 1 : 2;
                  return (
                    <div key={o.id} className="col-12 col-md-6 col-xxl-3">
                      <div className={`${sectionClass}__block`}>
                        <div className="vstack justify-content-between h-100 gap-3">
                          <div className=" vstack gap-2 gap-lg-4 align-items-center ">
                            <div className={`${sectionClass}__icon`}>
                              <img
                                src={o.icon}
                                alt=""
                                className="w-100 h-100 object-fit-contain"
                              />
                            </div>
                            <h6
                              className={`${sectionClass}__title`}
                              data-style={style}
                            >
                              {o.title}
                            </h6>
                            <p className={`${sectionClass}__content`}>
                              {o.content}
                            </p>
                          </div>
                          <div className="hstack justify-content-between align-items-center">
                            <div
                              className={`${sectionClass}__title`}
                              data-style={style}
                            >
                              {o.price}
                            </div>
                            <Link
                              to={o.link}
                              className={`${sectionClass}__action  e-btn e-btn--primary e-btn--wmax justify-self-end`}
                              data-style={style}
                            >
                              查看課程
                            </Link>
                          </div>
                        </div>
                        <div
                          className={`${sectionClass}__bg`}
                          data-style={style}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={`${sectionClass}__deco ${sectionClass}__deco--top`} />
        <div
          className={`${sectionClass}__deco ${sectionClass}__deco--bottom`}
        />
      </section>
    </>
  );
};

export default Main;
