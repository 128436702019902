import React from 'react';
import { qaList } from 'data/summer-camp';
import Item from './Item';
import Heading from '../Heading';
import { Title } from '../Title';

const Content = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__qa`;

  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section position-relative`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <div className="vstack gap-5">
              <Title
                LAYOUT_CLASS={LAYOUT_CLASS}
                text={'問答Q&A'}
                deco={'QUESTION'}
                decoStyle={2}
              />
              <div className="vstack gap-3">
                {qaList.map((v) => {
                  return (
                    <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${sectionClass}__deco ${sectionClass}__deco--bg`}
        ></div>
      </section>
    </>
  );
};

export default Content;
