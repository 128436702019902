import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

const Item = ({ LAYOUT_CLASS, data }) => {
  const sectionClass = `${LAYOUT_CLASS}__qa`;

  const { id, title, content } = data;

  const BREAKPOINT = 'md';
  return (
    <>
      <Accordion className={`${sectionClass}__accordion`}>
        <Accordion.Item eventKey="0" className={`${sectionClass}__item`}>
          <Accordion.Button
            className={`${sectionClass}__toggle`}
            data-style={2}
          >
            {title}
          </Accordion.Button>
          <Accordion.Body className={`${sectionClass}__wrapper`}>
            <div className={`${sectionClass}__content `}>
              <div className="row g-3">
                {Array.isArray(content) ? (
                  content.map((o) => (
                    <div
                      key={o.id}
                      className={`col-12 col-${BREAKPOINT}-4 vstack gap-3`}
                    >
                      <div className={`${sectionClass}__title`} data-style={2}>
                        {o.title}
                      </div>
                      <ol>
                        {o.list.map((v, i) => (
                          <li key={i} className={`${sectionClass}__text`}>
                            {i + 1}. {v}
                          </li>
                        ))}
                      </ol>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <p className={`${sectionClass}__text`}>{content}</p>
                  </div>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Item;
