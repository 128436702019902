import Banner from 'components/Banner';
import { Carousel } from 'components/SpringCourse2025/Carousel';
import { Course } from 'components/SpringCourse2025/Course';
import { Feature } from 'components/SpringCourse2025/Feature';
import { KeyVisual } from 'components/SpringCourse2025/KeyVisual';
import { QA } from 'components/SpringCourse2025/QA';
import { Schedule } from 'components/SpringCourse2025/Schedule';
import { Title } from 'components/SpringCourse2025/Title';
import React from 'react';

const SpringCourse2025 = () => {
  const LAYOUT_CLASS = 'l-spring-course-2025';
  return (
    <>
      <div className={LAYOUT_CLASS}>
        <KeyVisual LAYOUT_CLASS={LAYOUT_CLASS} />
        <Course LAYOUT_CLASS={LAYOUT_CLASS} />
        <Feature LAYOUT_CLASS={LAYOUT_CLASS} />
        <Schedule LAYOUT_CLASS={LAYOUT_CLASS} />
        <QA LAYOUT_CLASS={LAYOUT_CLASS} />
        <Carousel LAYOUT_CLASS={LAYOUT_CLASS} />
      </div>
    </>
  );
};

export default SpringCourse2025;
