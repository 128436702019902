import React from 'react';
import Heading from '../Heading';
import { scheduleList } from 'data/spring-course-2025';
import { Link } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Title } from '../Title';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__schedule`;
  const { width } = useWindowDimensions();
  const isMobile = width && width < 992;

  const headList = [
    { id: 'course', title: '課程', keys: ['title', 'subtitle'] },
    {
      id: 'time',
      title: '上課時間',
      keys: ['date', 'weekday', 'time', 'totalTime'],
    },
    { id: 'location', title: '地點', keys: ['location'] },
    { id: 'price', title: '金額', keys: ['price', 'earlyPrice', 'groupPrice'] },
    { id: 'tutor', title: '老師', keys: ['tutor'] },
    { id: 'action', title: '', keys: ['action'] },
  ];
  const spaceIdList = ['tutor', 'location'];
  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section position-relative`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <div className="vstack gap-3 gap-md-5">
              <Title
                LAYOUT_CLASS={LAYOUT_CLASS}
                text={'開課時間'}
                deco={'CLASS'}
                decoStyle={1}
              />
              <div className="overflow-scroll">
                <div className="vstack gap-0 gap-lg-5">
                  {['top', 'center', 'bottom'].map((placement, i) => {
                    const style = i % 2 === 0 ? 1 : 2;
                    return (
                      <div key={placement} className={`${sectionClass}__table`}>
                        <div className="position-relative">
                          <div
                            className={`${sectionClass}__row ${sectionClass}__row--head ${sectionClass}__row--${placement}`}
                            data-style={style}
                          >
                            {headList.map((o) => (
                              <div
                                key={o.id}
                                className={`${sectionClass}__col`}
                                data-style={style}
                              >
                                {o.title}
                              </div>
                            ))}
                          </div>
                        </div>
                        {scheduleList
                          .filter((o) => o.placement === placement)
                          .map((row, index) => (
                            <div
                              key={row.id}
                              className={`${sectionClass}__row`}
                              data-style={style}
                              data-row={index % 2 === 0 ? 'even' : 'odd'}
                            >
                              {headList.map((col) =>
                                col.keys.map((key, i) => (
                                  <div
                                    key={key}
                                    className={`${sectionClass}__col`}
                                    data-grid={key}
                                  >
                                    {key !== 'action' ? (
                                      `${
                                        isMobile &&
                                        col.id !== 'course' &&
                                        i === 0
                                          ? `${col.title}: `
                                          : ''
                                      }${row[key]}`
                                    ) : (
                                      <a
                                        key={col.id}
                                        href={row.href}
                                        className={`${sectionClass}__action ${sectionClass}__action--${placement} e-btn e-btn--primary e-btn--wmax`}
                                        data-style={
                                          isMobile
                                            ? index % 2 === 0
                                              ? 2
                                              : 1
                                            : style
                                        }
                                      >
                                        報名
                                      </a>
                                    )}
                                  </div>
                                ))
                              )}
                            </div>
                          ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${sectionClass}__deco ${sectionClass}__deco--top`} />
      </section>
    </>
  );
};

export default Main;
