import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

// Google Analytics 4（GA4）
import ReactGA from 'react-ga';

import {
  CourseDataContext,
  OverviewDataContext,
  HeaderContext,
} from 'utils/context';

import Home from 'pages/Home';
import Course from 'pages/Course';
import Overview from 'pages/Overview';
import NotFound from 'pages/NotFound';
import LatestNews from 'pages/LatestNews';
import CompetitionTraining from 'pages/CompetitionTraining';
import CourseIntro from 'pages/CourseIntro';
import LearningPath from 'pages/LearningPath';
import SummerCamp from 'pages/SummerCamp';

import Header from 'components/Header';
import Footer from 'components/Footer';
import PageTempelate from 'components/PageTempelate';
import WinterCamp from 'pages/WinterCamp';
import StudentFeedback from 'pages/StudentFeedback';
import StudyAbroad from 'pages/StudyAbroad';
import WinterCamp2025 from 'pages/WinterCamp2025';
import { KeyVisual } from 'components/WinterCamp2025/KeyVisual';
import SpringCourse2025 from 'pages/SpringCourse2025';

function App() {
  const [courseData, setCourseData] = useState({
    id: '',
    cover: '',
    title: '',
    subtitle: '',
    description: '',
    introduction: '',
    age: '',
    priorKnowledge: '',
    homework: 0,
    project: 0,
    skills: [],
    classes: [],
    length: '',
    originalPrice: 0,
    plan: [],
    more: [],
    outline: [],
    overviewLink: '',
  });
  const [overviewData, setOverviewData] = useState({
    id: '',
    title: '',
    overview: [],
  });
  const [showCanvas, setShowCanvas] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    // ReactGA.initialize('G-TYEVVKNDDZ');
    ReactGA.initialize('AW-11015970806');
  }, []);
  return (
    <>
      <HeaderContext.Provider value={{ showCanvas, setShowCanvas }}>
        <CourseDataContext.Provider value={{ courseData, setCourseData }}>
          <OverviewDataContext.Provider
            value={{ overviewData, setOverviewData }}
          >
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/course/:courseId" element={<Course />} />
                <Route path="/course/*" element={<NotFound />} />
                <Route
                  path="/course-intro/:courseId"
                  element={<CourseIntro />}
                />
                <Route path="/course-intro/*" element={<NotFound />} />
                <Route path="/latest-news" element={<LatestNews />} />
                <Route
                  path="/competition-training"
                  element={<CompetitionTraining />}
                />
                <Route
                  path="/learning-path"
                  element={
                    <PageTempelate
                      title="學習地圖"
                      subtitle="Learning Path"
                      content={<LearningPath />}
                    />
                  }
                />
                <Route
                  path="/summer-camp"
                  element={
                    <PageTempelate
                      title="2024暑期班資訊"
                      subtitle="Summer Camp"
                      content={<SummerCamp />}
                      bannerStyle="summer"
                      xlCol="10"
                    />
                  }
                />
                <Route path="/winter-camp" element={<WinterCamp />} />
                <Route path="/winter-camp-2025" element={<WinterCamp2025 />} />
                <Route
                  path="/spring-course-2025"
                  element={<SpringCourse2025 />}
                />
                <Route path="/study-abroad" element={<StudyAbroad />} />
                <Route
                  path="/student-feedback"
                  element={
                    <PageTempelate
                      title="學生回饋"
                      subtitle="Student Feedback"
                      content={<StudentFeedback />}
                    />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </OverviewDataContext.Provider>
        </CourseDataContext.Provider>
      </HeaderContext.Provider>
    </>
  );
}

export default App;
