import React from 'react';

const Icon = ({ sectionClass }) => {
  return (
    <>
      <svg
        width="134"
        height="134"
        viewBox="0 0 134 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${sectionClass}__icon`}
      >
        <g clip-path="url(#clip0_2665_2389)">
          <path
            d="M116.249 17.0117L47.9743 80.3507L19.1004 49.5374L0 67.4355L46.6473 117.216L134 36.2532L116.249 17.0117Z"
            fill="#BB823F"
          />
        </g>
        <defs>
          <clipPath id="clip0_2665_2389">
            <rect width="134" height="134" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Icon;
