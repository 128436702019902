import React from 'react';

import { Title } from '../Title';
import { featureList } from 'data/spring-course-2025';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__feature`;

  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section overflow-hidden`}
      >
        <div className="vstack gap-3 gap-md-5">
          <Title
            LAYOUT_CLASS={LAYOUT_CLASS}
            text={'ThousandAI的特色'}
            deco={'FEATURE'}
            decoStyle={2}
          />
          <div className={`${sectionClass}__row position-relative`}>
            <div className="row justify-content-center g-0">
              <div className="col-11">
                <div className="row justify-content-center g-3 g-md-5">
                  {featureList.map((o) => (
                    <div key={o.id} className="col-12 col-md-6 col-xxl-3">
                      <div className="vstack align-items-center gap-3">
                        <div className={`${sectionClass}__block`}>
                          <div className={`${sectionClass}__icon`}>
                            <img
                              src={o.icon}
                              alt={o.title}
                              className="e-img e-img--contain"
                            />
                          </div>
                        </div>
                        <h6 className={`${sectionClass}__title`}>{o.title}</h6>
                        <p className={`${sectionClass}__content`}>
                          {o.content}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`${sectionClass}__deco ${sectionClass}__deco--center`}
            />
            <div
              className={`${sectionClass}__deco ${sectionClass}__deco--bg`}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
