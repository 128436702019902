import React from 'react';
import Icon from './Icon';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__key-visual`;
  return (
    <>
      <section className={`${sectionClass} ${LAYOUT_CLASS}__section u-section`}>
        <div className="row justify-content-center g-0">
          <div className="col-12">
            <div
              className={`${sectionClass}__wrapper vstack align-items-center justify-content-center gap-3`}
            >
              <h1 className={`${sectionClass}__title`}>APCS</h1>
              <h1 className={`${sectionClass}__title`}> 資工系的前哨戰</h1>
              <h2 className={`${sectionClass}__subtitle`}>2025春季程式班</h2>
              <div className={`${sectionClass}__content`}>
                <div className={`${sectionClass}__text hstack gap-2`}>
                  <Icon sectionClass={sectionClass} />
                  豐富學習歷程檔案
                </div>
                <div className={`${sectionClass}__text hstack gap-2`}>
                  <Icon sectionClass={sectionClass} />
                  大學推甄升學加分
                </div>
                <div className={`${sectionClass}__text hstack gap-2`}>
                  <Icon sectionClass={sectionClass} />
                  多元入學首要選擇
                </div>
                <div className={`${sectionClass}__text hstack gap-2`}>
                  <Icon sectionClass={sectionClass} />
                  臺大師資品質保證！
                </div>
              </div>
              <div
                className={`${sectionClass}__tags hstack gap-3 justify-content-center`}
              >
                <span className={`${sectionClass}__tag`}>入門班</span>
                <span className={`${sectionClass}__divider`} />
                <span className={`${sectionClass}__tag`}>進階班</span>
                <span className={`${sectionClass}__divider`} />
                <span className={`${sectionClass}__tag`}>大師班</span>
                <span className={`${sectionClass}__divider`} />
                <span className={`${sectionClass}__tag`}>題庫班</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
