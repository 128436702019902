import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import swiperImg1 from 'img/spring-course-2025/carousel/1.jpg';
import swiperImg2 from 'img/spring-course-2025/carousel/2.jpg';
import swiperImg3 from 'img/spring-course-2025/carousel/3.jpg';
import swiperImg4 from 'img/spring-course-2025/carousel/4.jpg';
import swiperImg5 from 'img/spring-course-2025/carousel/5.jpg';

import useWindowDimensions from 'hooks/useWindowDimensions';
import Heading from '../Heading';
import { Title } from '../Title';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__carousel`;

  const [activeSlide, setActiveSlide] = useState(1);
  const { width } = useWindowDimensions();
  const isMobile = width && width < 576;

  const picList = [
    {
      id: 1,
      img: swiperImg1,
    },
    {
      id: 2,
      img: swiperImg2,
    },
    {
      id: 3,
      img: swiperImg3,
    },
    {
      id: 4,
      img: swiperImg4,
    },
    {
      id: 5,
      img: swiperImg5,
    },
  ];

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 3,
    },
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex + 1);
  };

  const handleClassName = (i) => {
    if (i === activeSlide) {
      return 'active';
    } else if (i === activeSlide - 1) {
      return 'prev';
    } else if (i === activeSlide + 1) {
      return 'next';
    } else {
      return '';
    }
  };
  return (
    <>
      <section className={`${sectionClass} position-relative overflow-hidden`}>
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <Title
              LAYOUT_CLASS={LAYOUT_CLASS}
              text={'課程花絮'}
              deco={'PICTURE'}
              decoStyle={1}
            />
            <Swiper
              breakpoints={breakpoints}
              speed={1000}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: `.${sectionClass}__action.prev`,
                nextEl: `.${sectionClass}__action.next`,
              }}
              modules={[Autoplay, Navigation]}
              className={`${LAYOUT_CLASS}__${
                !isMobile ? 'carousel__swiper' : 'swiper'
              }`}
              onSlideChange={handleSlideChange}
            >
              {picList.map((v, i) => {
                const { id, title, img } = v;
                return (
                  <SwiperSlide
                    key={id}
                    className={
                      !isMobile
                        ? `${sectionClass}__item ${handleClassName(i)}`
                        : `${LAYOUT_CLASS}__swiper__item`
                    }
                  >
                    <img
                      src={img}
                      alt={title}
                      className={`e-img e-img--cover ${
                        !isMobile
                          ? `${sectionClass}__img ${handleClassName(i)}`
                          : ''
                      } `}
                    />
                  </SwiperSlide>
                );
              })}
              <button className={`${sectionClass}__action prev`}>
                <FontAwesomeIcon
                  icon={solid('angle-left')}
                  className="fa-fw e-icon--light"
                />
              </button>
              <button className={`${sectionClass}__action next`}>
                <FontAwesomeIcon
                  icon={solid('angle-right')}
                  className="fa-fw e-icon--light"
                />
              </button>
            </Swiper>
          </div>
        </div>
        <div className={`${sectionClass}__bg`}></div>
      </section>
    </>
  );
};

export default Main;
