import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';

const Main = (props) => {
  const { text, deco, decoStyle, LAYOUT_CLASS } = props;
  const { width } = useWindowDimensions();
  return (
    <>
      <div className={`${LAYOUT_CLASS}__title__bg`} data-style={decoStyle}>
        {deco.toUpperCase()}
        <h1 className={`${LAYOUT_CLASS}__title__text`} data-style={decoStyle}>
          {text}
        </h1>
      </div>
    </>
  );
};

export default Main;
